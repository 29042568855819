
























































































































































import Vue from "vue";
import formSchemaSource from "@/common/form/home";

export default Vue.extend({
  data() {
    return {
      id: 1,
      formSchemaSource,
      form: {},
      home: {},
      categorys: [],
      services: [],
      certificates: [],
      partners: [],
    };
  },
  mounted() {
    console.log(!!this.id);
    this.getAllDatas();
    this.doAnimation();
  },

  methods: {
    getAllDatas() {
      let data = this.$store.state.allData;
      this.categorys = data["categorys"];
      this.services = data["services"];
      this.certificates = data["certificates"];
      this.partners = data["partners"];
      this.home = data["home"][0];
    },

    doAnimation() {
      this.$ScrollReveal().reveal(".banner .container", {
        reset: true,
        distance: "200px",
        origin: "top",
        delay: 300,
      });
      this.$ScrollReveal().reveal(".part1_left", {
        distance: "150%",
        origin: "left",
      });
      this.$ScrollReveal().reveal(".part1_right", {
        distance: "150%",
        origin: "right",
      });
    },

    goService(id: number) {
      this.$router.push({
        path: "/detail/services/" + id,
      });
    },
  },
});
